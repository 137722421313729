var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4908d61ac53fc5830b753debfc6f8c1cc057484e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development'

const getIntegrations = () => {
  const useBrowserTracing = () => new Sentry.BrowserTracing({
    tracePropagationTargets: ['localhost/matchnopath'],
  })

  const useReplay = () => new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
  })

  if (environment === 'production') {
    return [
      useBrowserTracing(),
      useReplay(),
    ]
  }

  if (environment === 'staging') {
    return [
      useBrowserTracing(),
    ]
  }

  return []
}

Sentry.init({
  environment,
  dsn: SENTRY_DSN || 'https://623ccf6e68ec407cb35e9dca06ea1be1@o4503962513047552.ingest.sentry.io/4504517371756544',
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,

  // capture 20% of sessions and 100% of sessions with errors
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  integrations: getIntegrations(),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
